import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SiteContext = createContext();

export function SiteProvider({ children }) {
  const [selectedSiteId, setSelectedSiteId] = useState(null);

  // Initialize from localStorage on mount
  useEffect(() => {
    const savedSiteId = localStorage.getItem('selectedSiteId');
    if (savedSiteId) setSelectedSiteId(savedSiteId);
  }, []);

  const updateSelectedSite = (siteId) => {
    localStorage.setItem('selectedSiteId', siteId);
    setSelectedSiteId(siteId);
  };

  return (
    <SiteContext.Provider value={{ selectedSiteId, updateSelectedSite }}>
      {children}
    </SiteContext.Provider>
  );
}

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


export const useSite = () => {
  return useContext(SiteContext);
};

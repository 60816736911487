import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useRoutes, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import createThemeRoutesWithLang from './routes/Router';
import Loader from './layouts/loader/Loader';
import { clearStoreAndLogout, logout, profileUser } from './store/apps/user/userSlice';
// import Hotjar from './Hotjar';
import ReactGA from 'react-ga4';
import "./assets/scss/custom-classes.css"
import { socket } from './socket';
import toast, { Toaster } from 'react-hot-toast';
import { getModuleByPackId } from './store/apps/paimentMethod/packSlice';
import { SiteProvider } from './Context/SiteContext';

const App = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'ar';
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthAuth, users } = useSelector((state) => state.userReducer);
  ReactGA.initialize('G-XX2R3X4BMR');
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();


  const token = Cookies.get('token');
  const storedSiteId = localStorage.getItem('selectedSiteId');
  const { PackageData } = useSelector((state) => state.packReducer);

  console.log(PackageData, "PackageDataPackageData")
  useEffect(() => {
    dispatch(getModuleByPackId({ siteId: storedSiteId }))

  }, [storedSiteId, dispatch, users]);

  useEffect(() => {
    if (token && isAuthAuth) {
      dispatch(profileUser());
    }
  }, [dispatch, isAuthAuth, token]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentLang = i18n.language;
    // Extract the part of the path that comes after the language segment
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}(?:-[A-Z]{2})?\//, '/');
    // Construct the new path with the selected language
    const newPath = `/${currentLang}${pathWithoutLang}`;
    // Only navigate if the new path is different from the current path
    if (newPath !== currentPath) {
      navigate(newPath, { replace: true });
    }
  }, [location, i18n.language, navigate]);




  useEffect(() => {
    if (users) {
      socket.connect()

      socket.on("notification", (message) => {
        toast(t => (
          <div className='d-flex gap-1 align-items-start'>
            <p className='m-0'>
              {message}
            </p>
            <button style={{ all: "unset" }} type='button' onClick={() => { toast.dismiss(t.id); }}><CloseIcon /></button>
          </div>
        ),
          {
            duration: Infinity,
            style: {
              maxWidth: "800px",
              background: "#06b6d4",
              color: "white",
              fontWeight: "bold"
            }
          })
      })

      return () => {
        socket.off("notification")
        socket.disconnect()
      }
    }
  }, [users])

  const routes = useRoutes(createThemeRoutesWithLang(i18n.language, PackageData));
  // console.log(routes)

  return (
    <Suspense fallback={<Loader />}>
      <Toaster />
      <div className={`${isRTL ? 'rtl' : 'ltr'}`} dir={isRTL ? 'rtl' : 'ltr'}>

        <ThemeSelector />
        <SiteProvider>
          {routes}</SiteProvider>
      </div></Suspense>
  );
};

export default App;

function CloseIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="white" width="20" height="20">
    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>

}
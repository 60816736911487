import React, { useEffect, useState } from 'react';
import CountryFlag from 'react-country-flag';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import Logosign from '../../assets/images/logos/Sign-up-amico.svg';
import { Axios } from '../../config';
import useTranslateTexts from '../../Context/useTranslateTexts';
import SecurasLogo from '../../layouts/logo/SecurasLogo';
import { getSiteUserByid, updateLang } from '../../store/apps/user/userSlice';
import BackupCodes from './twofa/BackupCodes';
import PhoneOTP from './twofa/PhoneOTP';
import RecoveryEmail from './twofa/RecoveryEmail';
import SelectMethod from './twofa/SelectMethod';
import TOTP from './twofa/TOTP';
import etoil from '../../assets/images/logos/etoil.svg';
import carreau from '../../assets/images/logos/carreau.svg';

const TwoFA = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()

  const [showAlertSuccess, setShowAlertSuccess] = useState(null);
  const [showAlertError, setShowAlertError] = useState(null);
  const {
    isFetchingLogin,
    isSuccessLogin,
    isErrorLogin,
    isAuthAuth,
    users,

    sites,

  } = useSelector((state) => state.userReducer);
  const [selectedSite, setSelectedSite] = useState(null);
  const [showSites, setShowSites] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const [siteSelected, setSiteSelected] = useState(false);
  const [method, setMethod] = useState("")
  const [twofaUser, set2faUser] = useState(null)

  useEffect(() => {
    if (!twofaUser) return;

    if (twofaUser?.totpEnabled) {
      setMethod("totp");
    }
    // else if (twofaUser?.otpPhoneEnabled) {
    //   setMethod("phone-otp");
    // }
    else {
      setMethod("select")
    }

  }, [twofaUser])

  // Options de langues pour le sélecteur
  const languageOptions = [
    { value: 'en', label: 'En', countryCode: 'GB' }, // Anglais (Royaume-Uni)
    { value: 'fr-FR', label: 'Fr', countryCode: 'FR' }, // Français (France)
    { value: 'ar', label: 'Ar', countryCode: 'TN' }, // Arabe (Arabie TUNISIE)
  ];


  // Utiliser la fonction pour définir la langue sélectionnée
  // const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(option => option.value === i18n.language) || languageOptions[0]);
  // Utiliser la fonction pour définir la langue sélectionnée
  const [selectedLanguage, setSelectedLanguage] = useState(
    // Cherche une option correspondant à la langue actuelle, sinon définit explicitement l'anglais comme langue par défaut.
    languageOptions.find(option => option.value.split('-')[0] === i18n.language.split('-')[0]) ||
    languageOptions.find(option => option.value === 'en') // Assure que 'en' est utilisé comme fallback
  );

  // Fonction pour gérer le changement de langue
  const handleLanguageChange = async (option) => {
    setSelectedLanguage(option);
    await i18n.changeLanguage(option.value);
    dispatch(updateLang({ userId: users?.id, newLang: i18n.language }));


  };
  const currentLanguage = i18n.language
  const texts = {
    bie: 'Welcome!',
    con: 'Log in to access CyberShield',
    em: 'E-mail',
    pw: 'Password',
    plc: 'Enter your password',
    svm: 'Remember me',
    selsi: 'Please select site',
    cyb: ' créée avec',
    ins: 'Registration',
    comp: " Si vous n'avez pas de compte, veuillez vous inscrire",
    enc: 'En cas de soucis merci de nous contacter sur',
    conn: 'Login',
    verifyOtp: 'Vérifier',
    invalidOTP: "Code invalide",
    pr: 'par',
    aleSit: 'The connection was successful',
    alec: 'Please select the website',
    aleAdd: 'Please add your website',
    emI: 'Email is invalid',
    emReq: 'Email is required',
    pass: 'Password must be at least 6 characters',
    passReq: 'Password is required',
    typS: "Type of Site",
    btn: 'Add Site',
    selec: "Select type",
    alertC: " CyberShield, Your 1st Defense Line ",
    alertA: 'Against Cyber Attacks',
    alcard: 'Start 2-Months free trial. No card required.',
    alS: 'Protect your Wordpress website now.',
    enterOtp: 'Veuillez entrer le code OTP (via l\'application d\'authentification)',
  }

  const translatedTexts = useTranslateTexts(texts);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //     rememberMe: false,
  //     otp: ''
  //   },
  // });
  const siteSchema = Yup.object({
    url: Yup.string().required('URL is required').url('Invalid URL format'),
    typeSite: Yup.string().required('Type of site is required')
  });

  function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(decodeHtml(translatedTexts.emI))
      .required(decodeHtml(translatedTexts.emReq))
      .transform((value) => value.toLowerCase()), // Transformer pour mettre en minuscules
    password: Yup.string()
      .min(6, decodeHtml(translatedTexts.pass))
      .required(decodeHtml(translatedTexts.passReq)),
  })
  const { executeRecaptcha } = useGoogleReCaptcha();
  //console.log(executeRecaptcha)
  function setCookie(name, value, options) {
    options = options || {};

    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (options.expires) {
      if (options.expires instanceof Date) {
        cookie += `; expires=${options.expires.toUTCString()}`;
      } else {
        throw new Error('Invalid expiration date');
      }
    }

    if (options.secure) {
      cookie += '; secure';
    }

    if (options.sameSite) {
      cookie += `; sameSite=${options.sameSite}`;
    }
    document.cookie = cookie;
  }
  const normalizeLanguageCode = (code) => {
    return code.split('-')[0]; // Prend la première partie avant le tiret
  };
  const languageCode = normalizeLanguageCode(i18n.language);

  // Logique pour gérer la sélection d'un site
  const handleSiteChange = (e) => {
    const selectedSiteId = e.target.value;
    setSelectedSite(selectedSiteId);
    dispatch(getSiteUserByid(selectedSiteId))

    // console.log('siteselectionne', site)
    setSiteSelected(true);  // Marquer un site comme étant sélectionné
  };

  // Initialisation des messages d'alerte
  useEffect(() => {
    setShowAlertSuccess(null);
    setShowAlertError(null);
  }, []);


  // Déterminez le drapeau et le nom de la langue pour chaque option
  const formatOptionLabel = ({ label, countryCode }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CountryFlag
        countryCode={countryCode}
        svg
        style={{ width: '2em', height: '100%', marginRight: '10px' }}
      />
      {label}
    </div>
  );
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '125px', // Ajustez la largeur minimale selon vos besoins
      height: 'auto', // Ajustez la hauteur si nécessaire
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '5px', // Ajustez le padding pour plus d'espacement interne
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '1rem', // Ajustez la taille de la police selon vos besoins
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem', // Ajustez la taille de la police pour les options
    }),
  };

  useEffect(() => {
    async function get2faStatus() {
      try {
        const res = await Axios.get("/user/get2faStatus")

        set2faUser(res.data)
      } catch (err) {
        navigate(`/${currentLanguage}/auth/loginFormik`)
        console.log(err)
      }
    }

    get2faStatus()


  }, [])


  return (

    <div className="overflow" style={{ height: '100vh', background: '#f1efef' }}>
      <div className="p-0" style={{ height: '100vh', background: '#f1efef' }}>
        <Row className="w-100 h-100 m-0">

          <Col lg="7" md="12" className="d-flex flex-column justify-content-center" style={{ background: '#386eaf' }}>
            <div className="d-flex">
              <Card className="shadow-none w-100" style={{ background: '#386eaf' }}>


                <CardBody className="d-flex flex-column gap-5 text-white">
                <div className="d-flex justify-content-end text-black" style={{ marginRight: "auto",}}>
                <Select
                      options={languageOptions}
                      formatOptionLabel={formatOptionLabel}
                      onChange={handleLanguageChange}
                      value={selectedLanguage}

                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                
                  {showAlertError && <div className="alert alert-danger">{showAlertError}</div>}
                  {showAlertSuccess && (
                    <div className="alert alert-success" role="alert">
                      {showAlertSuccess}
                    </div>
                  )}

                  {
                    !twofaUser ? (
                      <div className="loader mx-auto" ></div>
                    ) :
                      method === "select" ? (
                        <SelectMethod setMethod={setMethod} twofaUser={twofaUser} />
                      ) :
                        method === "totp" ?
                          (
                            <TOTP setMethod={setMethod} twofaUser={twofaUser} />
                          ) :
                          method === "backup-codes" ? (
                            <BackupCodes setMethod={setMethod} twofaUser={twofaUser} />
                          )
                            // : 
                            // method === "phone-otp" ? (
                            //   <PhoneOTP setMethod={setMethod} twofaUser={twofaUser} />
                            // )
                            :
                            method === "recovery-email" ? (
                              <RecoveryEmail setMethod={setMethod} twofaUser={twofaUser} />
                            ) :
                              ""
                  }
                  <div className="text-center">
                    <p>
                      {decodeHtml(translatedTexts?.enc)}
                      <br />
                      <a href="mailto:contact@securas.fr">contact@securas.fr</a>
                    </p>
                  </div>



                  <div className="text-center">

                    <Link to={`/${i18n.language}/auth/loginFormik`} className="btn btn-light w-50 mt-2">   {decodeHtml(translatedTexts?.conn)}</Link>

                  </div>

                  {/* )} */}

                  <div className="mt-1 text-center">
                    <p>
                      © {currentYear} CyberShield. {translatedTexts?.cyb}
                      <span role="img" aria-label="heart">
                        ❤️
                      </span>
                      {translatedTexts?.pr} <a href="https://www.securas.fr">Securas</a>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col
            lg="5"
            md="12"

            style={{
              backgroundImage: `url(${etoil})`,
              backgroundPosition: "center top, right top",
              backgroundSize: "contain, contain",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundBlendMode: "overlay",
              background: "radial-gradient(circle, #2CD7B2 0%, #2173E3 100%)"
            }}
            className="d-none d-lg-flex align-items-center justify-content-center position-relative"

          >



            <div style={{
              backgroundImage: `url(${etoil})`,
              backgroundPosition: "top top, right top",
              backgroundSize: "contain, contain",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundBlendMode: "overlay",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%"

            }} className=" position-absolute"
            >
              <div style={{
                backgroundImage: `url(${carreau})`,
                backgroundPosition: "center center, right top",
                backgroundSize: "contain, contain",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundBlendMode: "overlay" // Optional: helps blend gradient with images}}
              }}>

                < div
                  className="d-flex align-items-center justify-content-center position-relative"
                // backgroundImage: `url(${etoil}), url(${carreau})`,

                >
                  <img
                    style={{
                      width: "80%",  // Set desired width
                      height: "80%", // Set desired height
                      objectFit: "contain", // Optional: controls how the image fits
                      backgroundPosition: "center center, right top",
                      backgroundSize: "cover, center",
                      backgroundRepeat: "no-repeat, no-repeat",
                      backgroundBlendMode: "overlay"
                    }}
                    src={Logosign}
                    alt="Logo Sign"
                    className="position-relative"
                  />                       </div>
              </div>
            </div>




          </Col>

        </Row>
      </div>
    </div>
  );
};

export default TwoFA;

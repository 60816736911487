import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";


//get all PACK
// Async thunk to fetch all moduleLists
export const fetchAllModuleList = createAsyncThunk(
    "moduleLists/fetchAll",
    async ({userRole}, { rejectWithValue }) => {
        try {
            let url = `/moduleList/getAllmoduleLists?userRole=${userRole}`;
            let res = await Axios.get(url);
            console.log("res", res);
            return res.data;
        } catch (error) {
            console.error("Error fetching moduleLists:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);


const initialState = {
    moduleLists: [], // Initialized as an empty array
    status: null,
    error: null,
    isFetchingModuleLists: false,
    isFetchingFetchAllModuleList: false,
    isSuccessFetchAllModuleList: false,
    isErrorFetchAllModuleList: false,
};
export const moduleListSlice = createSlice({
    name: "moduleLists",
    initialState,
    

    reducers: {
        clearModuleListData: () => initialState
    },    extraReducers: (builder) => {
        //add packs extra reducers
        builder
            // ----------------------- get Pack------------------
            .addCase(fetchAllModuleList.pending, (state) => {
                state.status = "loading";
                // state.isFetchingModuleLists = true;
                state.isFetchingFetchAllModuleList= true;
                state.isSuccessFetchAllModuleList= false;
                state.isErrorFetchAllModuleList= false;
            })
            .addCase(fetchAllModuleList.fulfilled, (state, { payload }) => {
                // console.log("Fetched modules:", action.payload);
                state.moduleLists = payload;
                // state.isFetchingModuleLists = false;
                state.isFetchingFetchAllModuleList= false;
                state.isSuccessFetchAllModuleList= true;
                state.isErrorFetchAllModuleList= false;
            })
            .addCase(fetchAllModuleList.rejected, (state, action) => {
                state.isFetchingFetchAllModuleList= false;
                state.isSuccessFetchAllModuleList= false;
                state.isErrorFetchAllModuleList= true;

            })

         
    },


});
export const { clearModuleListData } = moduleListSlice.actions;

export default moduleListSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

//add PACK
export const createPack = createAsyncThunk("pack/add", async ({ pack, userRole }) => {
    try {
        let url = `/package/addPackage?userRole=${userRole}`;
        const result = await Axios.post(url, pack);

        return result.data;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error to handle it in the calling component
    }
});
//get all PACK
// Async thunk to fetch all packages
export const fetchAllPackages = createAsyncThunk(
    "packages/fetchAll",
    async ({ userId }, { rejectWithValue }) => {
        try {
            let url = `/package/getAllpackages`;
            let res = await Axios.get(url);
            console.log("res", res);
            return res.data;
        } catch (error) {
            console.error("Error fetching packages:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);


//del pack
export const deletePack = createAsyncThunk("packs/delete", async ({ id, userId, userRole }, { rejectWithValue }) => {
    try {
        // Construct the URL with query parameters
        let url = `/package/deletePackagebyId?id=${id}&userRole=${userRole}`;
        let res = await Axios.delete(url);
        return res.data;
    } catch (error) {
        console.error("Error deleting package:", error);
        return rejectWithValue(error.response?.data || "Something went wrong");
    }
});



export const updatePack = createAsyncThunk(
    "packs/update",
    async ({ id, userId, updatedData, userRole }, { rejectWithValue }) => {
        try {
            // Construct the URL with query parameters
            let url = `/package/updatePackageById?id=${id}&userRole=${userRole}`;
            let res = await Axios.put(url, updatedData);
            return res.data;
        } catch (error) {
            console.error("Error updating package:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

export const fetchPackageById = createAsyncThunk(
    "packages/fetchById",
    async ({ id }, { getState, rejectWithValue }) => {

        try {
            const response = await Axios.get(`/package/getPackagebyid?id=${id}`); // Ensure this matches your backend endpoint
            if (response.status !== 200) {
                throw new Error('Failed to fetch package');
            }
            return response.data;
        } catch (error) {
            console.error("Error fetching package by ID:", error);
            return rejectWithValue(error.response?.data || "Failed to fetch package");
        }
    }
);

export const getModuleByPackId = createAsyncThunk(
    "packages/getModuleByPackIdd",
    async ({ siteId }, { rejectWithValue }) => {

        try {
            let url
            url='/package/getModuleByPackId'
            if(siteId){
                url+=`?siteId=${siteId}`
            }
            const response = await Axios.get(url); // Ensure this matches your backend endpoint

            return response.data;
        } catch (error) {
            console.error("Error fetching package by ID:", error);
            return rejectWithValue(error.response?.data || "Failed to fetch package");
        }
    }
);

const initialState = {
    packages: [], // Initialized as an empty array
    package: null,
    status: null,
    error: null,
    isFetchingPackages: false,
    PackageData: [],
    PackageDataDetail: [],
    PackagetStatus: null,
    fetchModulePack: false,
    successModulePack: false,
    errorModulePack: false,
    isFetchingCreatePackage: false,
    isSuccessCreatePackage: false,
    isErrorCreatePackage: false,
    isFetchingFetchAllPackages: false,
    isSuccessFetchAllPackages: false,
    isErrorFetchAllPackages: false,
    isFetchingDeletePack: false,
    isSuccessDeletePack: false,
    isErrorDeletePack: false,
    isFetchingUpdatePack: false,
    isSuccessUpdatePack: false,
    isErrorUpdatePack: false,
    isFetchingFetchPackageById: false,
    isSuccessFetchPackageById: false,
    isErrorFetchPackageById: false,
    isFetchingGetModuleByPackId: false,
    isSuccessGetModuleByPackId: false,
    isErrorGetModuleByPackId: false,
};
export const packSlice = createSlice({
    name: "packs",
    initialState,
    reducers: {
        clearPackagedData: () => initialState
    },
    extraReducers: (builder) => {
        //add packs extra reducers
        builder
            .addCase(createPack.pending, (state) => {
                state.isFetchingCreatePackage = true;
                state.isSuccessCreatePackage = false;
                state.isErrorCreatePackage = false
            })
            .addCase(createPack.fulfilled, (state, action) => {
                state.isFetchingCreatePackage = false;
                state.isSuccessCreatePackage = true;
                state.isErrorCreatePackage = false
                state.packs = action.payload?.packs;
                return state;
            })
            .addCase(createPack.rejected, (state) => {
                state.isFetchingCreatePackage = false;
                state.isSuccessCreatePackage = false;
                state.isErrorCreatePackage = true
            })

            // ----------------------- get Pack------------------
            .addCase(fetchAllPackages.pending, (state) => {
                state.isFetchingFetchAllPackages = true;
                state.isSuccessFetchAllPackages = false;
                state.isErrorFetchAllPackages = false;
            })
            .addCase(fetchAllPackages.fulfilled, (state, { payload }) => {
                // console.log("Fetched packages:", action.payload);

                state.packages = payload;
                state.isFetchingFetchAllPackages = false;
                state.isSuccessFetchAllPackages = true;
                state.isErrorFetchAllPackages = false;

            })
            .addCase(fetchAllPackages.rejected, (state, action) => {
                state.isFetchingFetchAllPackages = false;
                state.isSuccessFetchAllPackages = false;
                state.isErrorFetchAllPackages = true;
            })

            // ----------------------- get Pack------------------
            // builder
            //   .addCase(getPack.pending, (state) => {
            //     state.status = "loading";
            //   })
            //   .addCase(getPack.fulfilled, (state, action) => {
            //     state.status = "fulfilled";
            //     state.packs = action.payload?.pack;
            //   })
            //   .addCase(getPack.rejected, (state) => {
            //     state.status = "failed";
            //   });

            // -----------------------DELETE pack ----------------------

            .addCase(deletePack.pending, (state) => {
                state.isFetchingDeletePack = true;
                state.isSuccessDeletePack = false;
                state.isErrorDeletePack = false;
            })
            .addCase(deletePack.fulfilled, (state, action) => {
                state.isFetchingDeletePack = false;
                state.isSuccessDeletePack = true;
                state.isErrorDeletePack = false;
                const deletedId = action.meta.arg.id;
                state.packages = state.packages.filter((pack) => pack._id !== deletedId);
            })
            .addCase(deletePack.rejected, (state) => {
                state.isFetchingDeletePack = false;
                state.isSuccessDeletePack = false;
                state.isErrorDeletePack = true;
            })
            // ----------------------- Update Pack ------------------
            .addCase(updatePack.pending, (state) => {
                state.isFetchingUpdatePack = true;
                state.isSuccessUpdatePack = false;
                state.isErrorUpdatePack = false;
            })
            .addCase(updatePack.fulfilled, (state, action) => {
                state.isFetchingUpdatePack = false;
                state.isSuccessUpdatePack = true;
                state.isErrorUpdatePack = false;
                const updatedPackage = action.payload; // Updated package data from API response
                const index = state.packages.findIndex(pack => pack._id === updatedPackage._id);
                if (index !== -1) {
                    state.packages[index] = updatedPackage; // Update package in state
                }
            })
            .addCase(updatePack.rejected, (state, action) => {
                state.isFetchingUpdatePack = false;
                state.isSuccessUpdatePack = false;
                state.isErrorUpdatePack = true;
            })
            // get package by id 
            .addCase(fetchPackageById.pending, (state) => {
                state.isFetchingFetchPackageById= true;
                state.isSuccessFetchPackageById= false;
                state.isErrorFetchPackageById= false;
            })
            .addCase(fetchPackageById.fulfilled, (state, action) => {
                state.isFetchingFetchPackageById= false;
                state.isSuccessFetchPackageById= true;
                state.isErrorFetchPackageById= false;
                state.PackageDataDetail = action.payload;
            })
            .addCase(fetchPackageById.rejected, (state, action) => {
                state.isFetchingFetchPackageById= false;
                state.isSuccessFetchPackageById= false;
                state.isErrorFetchPackageById= true;
                // state.error = action.payload; // S/tore the error message
            })

            // get getModuleByPackId 
            .addCase(getModuleByPackId.pending, (state) => {
                // state.status = "loading";
                state.isFetchingGetModuleByPackId= true;
                state.isSuccessGetModuleByPackId= false;
                state.isErrorGetModuleByPackId= false;
            })

            .addCase(getModuleByPackId.fulfilled, (state, { payload }) => {
                // state.PackagetStatus = 'succeeded';
                state.isFetchingGetModuleByPackId= false;
                state.isSuccessGetModuleByPackId= true;
                state.isErrorGetModuleByPackId= false;

                // console.log(action,"  console.log(action)")
                state.PackageData = payload;
            })
            .addCase(getModuleByPackId.rejected, (state, action) => {
                // state.status = "failed";
                state.isFetchingGetModuleByPackId= false;
                state.isSuccessGetModuleByPackId= false;
                state.isErrorGetModuleByPackId= true;
                state.PackageData = [];
            })
    },


});
export const { clearPackagedData } = packSlice.actions;

export default packSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

//add PACK
export const createModulePackage = createAsyncThunk("pack/add", async ({ pack, userRole }) => {
    try {
        let url = `/package/addModulePack?userRole=${userRole}`;
        const result = await Axios.post(url, pack);
        
        return result.data;
    } catch (error) {
        console.error(error);
        throw { message: error.response?.data?.msg || "An error occurred", status: error.response?.status };
    }
});
// Async thunk to fetch all packages
// export const fetchAllPackagesModule = createAsyncThunk(
//     "AllModulePackages/fetchAll",
//     async (_, { rejectWithValue }) => {
//         try {
//             let url = `/package/getAllModulePackages`;
//             console.log(url,"urlurl")
//             let res = await Axios.get(url);
//             console.log("Response from API:", res); // Debug API response
//             return res.data;
//         } catch (error) {
//             console.error("Error fetching AllModulePackages:", error);
//             return rejectWithValue(error.response?.data || "Something went wrong");
//         }
//     }
// );

export const fetchAllPackagesModule = createAsyncThunk(
    "AllModulePackages/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            const url = `/package/getAllModulePackages`;
            const response = await Axios.get(url);
console.log('respppp',response)
            // Vérification de la réponse avant de retourner les données
            if (response.status !== 200) {
                throw new Error("Échec du chargement des packages");
            }

            return response.data;
        } catch (error) {
            console.error("Erreur lors de la récupération des packages:", error);

            return rejectWithValue(
                error.response?.data?.message || "Une erreur est survenue lors du chargement des données."
            );
        }
    }
);
//del pack
export const deleteModulePackageById = createAsyncThunk("deleteModulePackageById/delete", async ({ id, userId, userRole }, { rejectWithValue }) => {
    try {
        // Construct the URL with query parameters
        let url = `/package/deleteModulePackageById?id=${id}&userRole=${userRole}`;
        let res = await Axios.delete(url);
        return res.data;
    } catch (error) {
        console.error("Error deleting ModulePackage:", error);
        return rejectWithValue(error.response?.data || "Something went wrong");
    }
});
export const updateModulePackagesbyId = createAsyncThunk(
    "packs/update",
    async ({ pack, userRole  }, { rejectWithValue }) => {
        try {
            // Construct the URL with query parameters
            let url = `/package/updateModulePackagesbyId?userRole=${userRole}`;
            let res = await Axios.put(url, pack);
            return res.data;
        } catch (error) {
            console.error("Error updating ModulePackages:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

const initialState = {
    packagesModules: null,
    status: null,
    error: null,
isFetchingCreateModulePackage: false,
isSuccessCreateModulePackage: false,
isErrorCreateModulePackage: false,
isFetchingFetchAllPackagesModule: false,
isSuccessFetchAllPackagesModule: false,
isErrorFetchAllPackagesModule: false,
isFetchingDeleteModulePackageById: false,
isSuccessDeleteModulePackageById: false,
isErrorDeleteModulePackageById: false,
isFetchingUpdateModulePackagesbyId: false,
isSuccessUpdateModulePackagesbyId: false,
isErrorUpdateModulePackagesbyId: false,
};

export const packModuleSlice = createSlice({
    name: "packsModule",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createModulePackage.pending, (state) => {
                state.isFetchingCreateModulePackage= true;
                state.isSuccessCreateModulePackage= false;
                state.isErrorCreateModulePackage= false;
            })
            .addCase(createModulePackage.fulfilled, (state, action) => {
                state.isFetchingCreateModulePackage= false;
                state.isSuccessCreateModulePackage= true;
                state.isErrorCreateModulePackage= false;
                                state.packagesModules = action.payload?.packsModule || [];
            })
            .addCase(createModulePackage.rejected, (state, action) => {
                state.isFetchingCreateModulePackage= false;
                state.isSuccessCreateModulePackage= false;
                state.isErrorCreateModulePackage= true;
            })
            .addCase(fetchAllPackagesModule.pending, (state) => {
                state.isFetchingFetchAllPackagesModule= true;
               state. isSuccessFetchAllPackagesModul= false;
                state.isErrorFetchAllPackagesModule= false;     
                  })
            .addCase(fetchAllPackagesModule.fulfilled, (state, { payload }) => {
                state.isFetchingFetchAllPackagesModule= false;
                state. isSuccessFetchAllPackagesModul= true;
                 state.isErrorFetchAllPackagesModule= false;  
                state.packagesModules = payload;

            })
            .addCase(fetchAllPackagesModule.rejected, (state, action) => {
                state.isFetchingFetchAllPackagesModule= false;
                state. isSuccessFetchAllPackagesModul= false;
                 state.isErrorFetchAllPackagesModule= true;  
            })
            // -----------------------DELETE packModule ----------------------

            .addCase(deleteModulePackageById.pending, (state) => {
                state.isFetchingDeleteModulePackageById= true;
                state.isSuccessDeleteModulePackageById= false;
                state.isErrorDeleteModulePackageById= false;     
                  })
            .addCase(deleteModulePackageById.fulfilled, (state, action) => {
                state.isFetchingDeleteModulePackageById= false;
                state.isSuccessDeleteModulePackageById= true;
                state.isErrorDeleteModulePackageById= false;     
                 const deletedId = action.meta.arg.id;
                state.packagesModules = state.packagesModules.filter((pm) => pm._id !== deletedId);

            })
            .addCase(deleteModulePackageById.rejected, (state) => {
                state.isFetchingDeleteModulePackageById= false;
                state.isSuccessDeleteModulePackageById= false;
                state.isErrorDeleteModulePackageById= true;     
                        })
            // ----------------------- Update Pack ------------------
            .addCase(updateModulePackagesbyId.pending, (state) => {
                state.isFetchingUpdateModulePackagesbyId= true
                state.isSuccessUpdateModulePackagesbyId= false;
                state.isErrorUpdateModulePackagesbyId= false;          })
            .addCase(updateModulePackagesbyId.fulfilled, (state, action) => {
                state.isFetchingUpdateModulePackagesbyId= false;
                state.isSuccessUpdateModulePackagesbyId= true;
                state.isErrorUpdateModulePackagesbyId= false;          
             const updatedModule = action.payload.updatedPackageModule; // Match the backend response structure
                state.packagesModules = state.packagesModules.map((pm) =>
                    pm._id === updatedModule?._id ? updatedModule : pm
                );
            })
            .addCase(updateModulePackagesbyId.rejected, (state, action) => {
                state.isFetchingUpdateModulePackagesbyId= false;
                state.isSuccessUpdateModulePackagesbyId= false;
                state.isErrorUpdateModulePackagesbyId= true;    
            });

    },
});

export default packModuleSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";

// Create Virement
export const createVirement = createAsyncThunk(
    "Virements/create",
    async ({ virementData }, { rejectWithValue }) => {
        console.log(virementData, "virementData")

        const { userId, siteId } = virementData;  // Destructure virementData to get required fields
        try {
            // Send the combined data (virementData) to the backend
            const response = await Axios.post(`/virement/addVirement?siteId=${siteId}&userId=${userId}`, virementData);

            if (response.status !== 200) {
                throw new Error('Failed to create virement');
            }

            return response.data; // Return the created virement data
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to create Virement');
        }
    }
);



// Fetch all virements
export const getAllVirementsAdmin = createAsyncThunk(
    "Virements/fetchAllAdmin",
    async ({ userRole }, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/virement/getAllVirmentsAdmin?userRole=${userRole}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Virement');
        }
    }
);




export const updateVirementById = createAsyncThunk(
    "virement/update",
    async ({ updateData }, { rejectWithValue }) => {
        try {
            const {virementId} = updateData;
            const {siteId } = updateData;
            const url = `/virement/updateVirementById?siteId=${siteId}&virementId=${virementId}`;

            const response = await Axios.put(url, { updateData });
            return response.data;
        } catch (error) {
            console.error("Error updating virement:", error);
            return rejectWithValue(error.response?.data || "Something went wrong during the update.");
        }
    }
);
export const getVirementById = createAsyncThunk(
    "virement/getbyid",
    async ({ virementId }, { rejectWithValue }) => {
        try {
            const url = `/virement/getVirementById?virementId=${virementId}`;
            const response = await Axios.get(url); // Send `isValidate` in the body
            return response.data;
        } catch (error) {
            console.error("Error updating virement:", error);
            return rejectWithValue(error.response?.data || "Something went wrong during the update.");
        }
    }
);

export const getVirementBySiteId = createAsyncThunk(
    "virement/getVirementBySiteId",
    async ({ siteId }, { rejectWithValue }) => {
        try {
            let url = `/virement/getVirementBySiteId?siteId=${siteId}`

            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(url);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch virement');
        }
    }
);
const initialState = {
    virementList: [],
    virementListUser: [],
    virementData: null,
    virementRef: null, // To store the virementRef
    virementStatus: 'idle',
    error: null,
    transactionSaved: false,
    isFetchingUpdateVirement: false,
    isSuccessUpdateVirement: false,
    isErrorUpdateVirement: false,
   
    isFetchingGetVirementBySiteId: false,
    isSuccessGetVirementBySiteId: false,
    isErrorGetVirementBySiteId: false,
  
    isFetchingCreateVirement: false,
    isSuccessCreateVirement: false,
    isErrorCreateVirement: false,
    isFetchingGetVirement: false,
    isSuccessGetVirement: false,
    isErrorGetVirement: false,
    virementclient: null,
    virementupdated: null,
    VirementBySiteId: [],

}
const virementSlice = createSlice({
    name: 'virement',
    initialState,
    reducers: {
        setTransactionSaved: (state, action) => {
            state.transactionSaved = action.payload;
        },
        clearVirementdData: () => initialState

    },
    extraReducers: (builder) => {
        builder
            // Create Virement
            .addCase(createVirement.pending, (state) => {
                state.isFetchingCreateVirement = true;
                state.isSuccessCreateVirement = false;
                state.isErrorCreateVirement = false;
            })
            .addCase(createVirement.fulfilled, (state, { payload }) => {
                state.isFetchingCreateVirement = false;
                state.isSuccessCreateVirement = true;
                state.isErrorCreateVirement = false;                // Add the new virement to the virement list
                if (!Array.isArray(state.virementList)) {
                    state.virementList = [];  // Ensure it's an array before pushing
                }
                state.virementList.push(payload?.newVirement);
                state.transactionSaved = true;  // Mark the transaction as saved
            })
            .addCase(createVirement.rejected, (state, action) => {
                state.isFetchingCreateVirement = false;
                state.isSuccessCreateVirement = false;
                state.isErrorCreateVirement = true;
            })
            // Get All Virements
          

            //update virement 

            .addCase(updateVirementById.pending, (state) => {
                state.isFetchingUpdateVirement = true;
                state.isErrorUpdateVirement = false;
                state.isSuccessUpdateVirement = false;
            })
            .addCase(updateVirementById.fulfilled, (state, { payload }) => {
                state.isFetchingUpdateVirement = false;
                state.virementupdated = payload;
                state.isSuccessUpdateVirement = true;
                state.isErrorUpdateVirement = false;
            })
            .addCase(updateVirementById.rejected, (state, { payload }) => {
                state.isFetchingUpdateVirement = false;
                state.isErrorUpdateVirement = true;
                state.isSuccessUpdateVirement = false;
            })
            .addCase(getVirementById.pending, (state) => {
                state.isFetchingGetVirement = true;
                state.isErrorGetVirement = false;
                state.isSuccessGetVirement = false;
            })
            .addCase(getVirementById.fulfilled, (state, { payload }) => {
                state.isFetchingGetVirement = false;
                state.virementclient = payload;
                state.isSuccessGetVirement = true;
                state.isErrorGetVirement = false;
            })
            .addCase(getVirementById.rejected, (state, { payload }) => {
                state.isFetchingGetVirement = false;
                state.isErrorGetVirement = true;
                state.isSuccessGetVirement = false;
            })
            .addCase(getVirementBySiteId.pending, (state) => {
                state.isFetchingGetVirementBySiteId = true;
                state.isSuccessGetVirementBySiteId = false;
                state.isErrorGetVirementBySiteId = false;
            })
            .addCase(getVirementBySiteId.fulfilled, (state, action) => {
                state.isFetchingGetVirementBySiteId = false;
                state.isSuccessGetVirementBySiteId = true;
                state.isErrorGetVirementBySiteId = false;
                state.VirementBySiteId = action.payload;
            })
            .addCase(getVirementBySiteId.rejected, (state, action) => {
                state.isFetchingGetVirementBySiteId = false;
                state.isSuccessGetVirementBySiteId = false;
                state.isErrorGetVirementBySiteId = true;
            })

    }
});
export const { clearVirementdData } = virementSlice.actions;

export default virementSlice.reducer;

// export const { actions: virementActions, reducer: virementReducer } = virementSlice;

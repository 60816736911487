import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap';
import Logosign from '../../assets/images/logos/Sign-up-amico.svg';
import etoil from '../../assets/images/logos/etoil.svg';
import carreau from '../../assets/images/logos/carreau.svg';
import useTranslateTexts from '../../Context/useTranslateTexts';
import SecurasLogo from '../../layouts/logo/SecurasLogo';
import ResetPassword from './ResetPassword';
import SelectMethod from './forgot-password/SelectMethod';
import TOTP from './forgot-password/TOTP';
import BackupCodes from './forgot-password/BackupCodes';
import PhoneOTP from './forgot-password/PhoneOTP';
import RecoveryEmail from './forgot-password/RecoveryEmail';
import Email from './forgot-password/Email';

const ForgotPassword = () => {
  const { i18n } = useTranslation()
  const [method, setMethod] = useState("select")
  const [resetMethods, setResetMethods] = useState(null)
  const texts = {
    bie: 'Welcome!',
    con: 'Log in to access CyberShield',
    em: 'E-mail',
    pw: 'Password',
    plc: 'Enter your password',
    svm: 'Remember me',
    selsi: 'Please select site',
    cyb: ' créée avec',
    ins: 'Registration',
    comp: " Si vous n'avez pas de compte, veuillez vous inscrire",
    enc: 'En cas de soucis merci de nous contacter sur',
    conn: 'Login',
    pr: 'par',
    aleSit: 'The connection was successful',
    alec: 'Please select the website',
    aleAdd: 'Please add your website',
    emI: 'Email is invalid',
    emReq: 'Email is required',
    pass: 'Password must be at least 6 characters',
    passReq: 'Password is required',
    typS: "Type of Site",
    btn: 'Add Site',
    selec: "Select type",
    alertC: " CyberShield, Your 1st Defense Line ",
    alertA: 'Against Cyber Attacks',
    alcard: 'Start 2-Months free trial.',
    alS: 'Protect your Wordpress website now.',
    frgp: "Forgot password",
    login: "Login"

  }

  const translatedTexts = useTranslateTexts(texts);


  return (

    <div className="overflow" style={{ height: '100vh', background: '#386eaf' }}>
    <div className="p-0" style={{ height: '100vh', background: '#386eaf' }}>
      <Row className="w-100 h-100 m-0">
      
          <Col lg="7" md="12" className="d-flex flex-column justify-content-center" style={{ background: '#f1efef' }}>
            <div className="d-flex">

              <div style={{ maxWidth: '600px', margin: 'auto', width: '100%', paddingTop: '50px' }}>
                {
                  !resetMethods ?
                    <Card>
                      <CardBody className="text-center">
                        <ResetPassword setMethod={setMethod} setResetMethods={setResetMethods} />
                      </CardBody>
                    </Card>
                    : method === "select" ?
                      <Card>
                        <CardBody className="text-center">
                          <SelectMethod setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                        </CardBody>
                      </Card>
                      : method === "email" ?
                        <Card>
                          <CardBody className="text-center">
                            <Email setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                          </CardBody>
                        </Card>
                        : method === "totp" ?
                          <Card>
                            <CardBody className="text-center">
                              <TOTP setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                            </CardBody>
                          </Card>
                          : method === "backup-codes" ?
                            <Card>
                              <CardBody className="text-center">
                                <BackupCodes setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                              </CardBody>
                            </Card>

                            : method === "recovery-email" ?
                              <Card>
                                <CardBody className="text-center">
                                  <RecoveryEmail setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                                </CardBody>
                              </Card>
                              : ""
                }
              </div>
            </div>
            <Link to={`/${i18n.language}/auth/loginFormik`} className="btn btn-primary  w-50 mt-2 mx-auto" >{translatedTexts?.login}</Link>
          </Col>

          <Col
                   lg="5"
                   md="12"
         
                   style={{
                     backgroundImage: `url(${etoil})`,
                     backgroundPosition: "center top, right top",
                     backgroundSize: "contain, contain",
                     backgroundRepeat: "no-repeat, no-repeat",
                     backgroundBlendMode: "overlay",
                     background: "radial-gradient(circle, #2CD7B2 0%, #2173E3 100%)"
                   }}
                   className="d-none d-lg-flex align-items-center justify-content-center position-relative"
         
                 >
         
         
         
                   <div style={{
                     backgroundImage: `url(${etoil})`,
                     backgroundPosition: "top top, right top",
                     backgroundSize: "contain, contain",
                     backgroundRepeat: "no-repeat, no-repeat",
                     backgroundBlendMode: "overlay",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     width: "100%",
                     height: "100%"
         
                   }} className=" position-absolute"
                   >
                     <div style={{
                       backgroundImage: `url(${carreau})`,
                       backgroundPosition: "center center, right top",
                       backgroundSize: "contain, contain",
                       backgroundRepeat: "no-repeat, no-repeat",
                       backgroundBlendMode: "overlay" // Optional: helps blend gradient with images}}
                     }}>
         
                       < div
                         className="d-flex align-items-center justify-content-center position-relative"
                       // backgroundImage: `url(${etoil}), url(${carreau})`,
         
                       >
<img
                  style={{
                    width: "80%",  // Set desired width
                    height: "80%", // Set desired height
                    objectFit: "contain", // Optional: controls how the image fits
                    backgroundPosition: "center center, right top",
                    backgroundSize: "cover, center",
                    backgroundRepeat: "no-repeat, no-repeat",
                    backgroundBlendMode: "overlay"
                  }}
                  src={Logosign}
                  alt="Logo Sign"
                  className="position-relative"
                />                       </div>
                     </div>
                   </div>
         
         
         
         
                 </Col>
         
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
